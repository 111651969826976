import { Link, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";

const generateTableOfContent = (data) => {
  const filterHeadings = data.filter((item) => item.type === "heading2");

  const render = [];

  filterHeadings.forEach((item) => {
    if (item.text === "") return;
    render.push(
      <ListItem>
        <Link
          href={
            "#" +
            item.text.toLowerCase().replace(/[&\\#, +()$~%.'":*?<>{}]/g, "-")
          }
          _hover={{ color: "#2b2f69" }}
          color="#3182ce"
          fontSize="lg"
          fontWeight="semibold"
        >
          {item.text}
        </Link>
      </ListItem>
    );
  });

  return (
    <UnorderedList spacing={"0.75rem"} mt="1.5rem">
      {render}
    </UnorderedList>
  );
};

export { generateTableOfContent };
