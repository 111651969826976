import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default function SEO({ title }) {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Helmet>
      <title>
        {title} {" | "} {result.site.siteMetadata.title}
      </title>
    </Helmet>
  );
}
