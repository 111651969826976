const standardToName = (standard) => {
  switch (standard) {
    case "9001":
      return "Quality Management System";
    case "14001":
      return "Environmental Management Systems";
    case "13485":
      return "Medical Devices — Quality Management Systems";
    case "22000":
      return "Food Safety Management Systems";
    case "22301":
      return "Business Continuity Management Systems";
    case "27001":
      return "Information Security Management Systems";
    case "45001":
      return "Occupational Health & Safety Management Systems";
    case "20000":
      return "Information Technology Service Management Systems";
    case "haccp":
      return "Hazard Analysis Critical Control Point";
    case "ims":
      return "INTEGRATED MANAGEMENT SYSTEM";
    default:
      return "";
  }
};

export { standardToName };
