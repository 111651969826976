import {
  Heading,
  Box,
  Container,
  Link,
  Button,
  Flex,
  Stack,
  Image,
} from "@chakra-ui/react";
import React from "react";
import ContactForm from "./ContactForm";
import Bgimage from "../images/bgimage.png";

export default function SinglePageHeadingSection({
  title,
  subtitle,
  imgSrc = null,
  imageSrc = Bgimage,
}) {
  return (
    <>
      <Box
        sx={{
          background: `url(${imageSrc}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <Container maxW="7xl" py={5}>
          <Box
            d="flex"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              align={"center"}
              flex={1}
              py={{ base: 12, md: 16 }}
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                flex={1}
                justify={"center"}
                align={"center"}
                position={"relative"}
                w={"full"}
              >
                {/* <Blob
                  flexDirection={{
                    base: "column",
                    md: "row",
                  }}
                  w={"200%"}
                  h={"180%"}
                  position={"absolute"}
                  top={"-30%"}
                  left={"-20%"}
                  zIndex={-1}
                  color={"blue.50"}
                /> */}
                <Box flex={1} textAlign={{ base: "center", md: "left" }}>
                  <Heading as="h1" size="2xl" color={"#ffffff"}>
                    {title}
                  </Heading>
                  <Heading as="h2" size="lg" mt={3} color="#ffffff">
                    {subtitle}
                  </Heading>
                  <Box
                    mx={{ base: "auto", md: "0" }}
                    sx={{
                      marginTop: "1.25rem",
                      marginBottom: "1.25rem",
                      width: "8rem",
                      height: "0.25rem",
                      backgroundColor: "#29a6ff",
                    }}
                  ></Box>
                  <Heading as="h6" size="md" mt={2} color="#ffffff">
                    Value Process <br /> 15+ Years of experience <br />{" "}
                    Certified 10000+ Companies
                  </Heading>
                </Box>
              </Flex>
            </Stack>
            <Box flex={1}>
              <Box
                maxW={"400px"}
                mx={"auto"}
                sx={{
                  "& > *": {
                    width: "100%",
                  },
                }}
              >
                <div id="contact-us"></div>
                <ContactForm />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

// export const Blob = (props) => {
//   return (
//     <Icon
//       width={"100%"}
//       viewBox="0 0 578 440"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
//         fill="currentColor"
//       />
//     </Icon>
//   );
// };
